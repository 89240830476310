import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ReactPlayer from "react-player/lazy"
import { isBrowser } from "../../../context/ApolloContext"
import TvAdsHomeCarousel from "../../TvAdsHomeCarousel"

const $ = isBrowser ? require("jquery") : {}

const TvAdsHomeSection = ({ intl, data }) => {
  const handleHashScrolling = () => {
    $(document).ready(function() {
      $("html, body").hide()
      if (window.location.hash === "#tv") {
        setTimeout(function() {
          $("html, body")
            .scrollTop(0)
            .show()
          $("html, body").animate(
            {
              scrollTop: $(window.location.hash).offset().top - 180,
            },
            1500
          )
        }, 0)
      } else {
        $("html, body").show()
      }
    })
  }

  useEffect(() => {
    isBrowser && handleHashScrolling()
  }, [])
  return (
    data && (
      <TvAdsWrapper id="tv">
        <Section padding={"10px"}>
          <SectionWrapper>
            <Title>On passe à la télé</Title>
            <PlayerWrapper>
              <ReactPlayer
                // url={
                //   "https://www.percko.com/videos/percko.com/eur-fr/home-page/home_page_-_video_footer_TV.mp4"
                // }
                // url={
                //   "https://www.percko.com/videos/percko.com/eur-fr/home-page/home_page_-_video_footer_TV_matelas_202502.mp4"
                // }
                url={
                  "https://www.percko.com/videos/percko.com/eur-fr/home-page/home_page_on-passe-a-la-TV-Passage-JT-20h-TF1-2025-03-07.mp4"
                }
                stopOnUnmount={true}
                // light="https://static.percko.com/uploads/3b76bd01-7633-48bf-a150-6f5f2afaaf32.jpg"
                // light="https://static.percko.com/uploads/602c8c91-b518-42f9-9803-87a4390e2820.jpg"
                // light="https://static.percko.com/uploads/71f616ea-65a9-4301-bdd1-d7ffa59c1ca7.jpg"dire
                light="https://static.percko.com/uploads/6cb24782-6ad1-48b8-8339-ba80e3b1ca42.jpg"
                playing={true}
                controls={true}
                muted={true}
                width="100%"
                height="100%"
              />
            </PlayerWrapper>
          </SectionWrapper>
        </Section>
        <Section style={{ background: "#e7f0f3" }}>
          <SectionWrapper>
            <Title>Ils parlent de nous</Title>
            <Text>
              Ils ont testé nos solutions et les ont approuvées. Découvrez les
              articles de presse, passages télévisés, émissions de radio...
            </Text>
            {data.slider && data.slider.list && data.slider_link_text && (
              <TvAdsSliderWrapper>
                <TvAdsHomeCarousel
                  data={data.slider.list}
                  sliderLinkText={data.slider_link_text}
                ></TvAdsHomeCarousel>
              </TvAdsSliderWrapper>
            )}
          </SectionWrapper>
        </Section>
      </TvAdsWrapper>
    )
  )
}

const TvAdsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  padding: ${({ padding }) => (padding ? "10px 20px 30px" : "30px 20px")};

  @media (max-width: 992px) {
    padding: ${({ padding }) => (padding ? "0px 20px 20px" : "30px 20px")};
  }
`

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1900px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h2`
  font-family: Museo;
  font-weight: 900;
  font-size: 35px;
  line-height: 1.2;
  margin: 0px 0px 15px;

  @media (max-width: 1600px) {
    font-size: 30px;
  }

  @media (max-width: 992px) {
    font-size: 24px;
  }
`

const Text = styled.div`
  font-family: "Gotham Book";
  margin-bottom: 20px;
  line-height: 20px;

  @media (max-width: 600px) {
    margin: 10px 0px;
  }

  p {
    margin: 0px;
  }

  p > a {
    font-family: "GothamLight";
    font-weight: bold;
    text-decoration: underline;
  }
`

const PlayerWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
  height: 450px;
  border-radius: 0px;
  overflow: hidden;
  position: relative;

  .react-player__preview {
    position: absolute;
    top: 0px;
  }

  iframe,
  video {
    @media (max-width: 600px) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  @media (max-width: 600px) {
    padding-top: 56%;
    width: calc(100% + 40px);
    height: auto;
    margin-left: -20px;
  }
`

const TvAdsSliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default injectIntl(TvAdsHomeSection)
